<template>
    <component :is="tag"
        :type="to ? undefined : 'button'"
        :to="to"
        class="rounded font-prenton-cond outline-none transition-colors duration-150
            focus-visible:rounded-2xs focus-visible:outline-2 focus-visible:outline-white
            focus-visible:ring-4 focus-visible:ring-blue-600"
        :disabled="disabled"
        :class="classes"
        :aria-label="ariaLabel"
        role="button">
        <slot></slot>
    </component>
</template>

<script lang="ts" setup>
import type { ButtonThemes } from '~~/types';
import { NuxtLink } from '#components';
import type { RouteLocationRaw } from 'vue-router';

const properties = withDefaults(defineProps<{
    size?: 'sm' | 'md' | 'base' | 'lg' | 'wide'
    theme?: ButtonThemes
    disabled?: boolean
    to?: RouteLocationRaw
    ariaLabel?: string
}>(), {
    size: 'base',
    theme: undefined,
    to: undefined,
    ariaLabel: undefined,
});

const tag = computed(() => {
    if (properties.to) {
        return NuxtLink;
    }

    return 'button';
});

const themes = computed<Record<string, string[]>>(() => ({
    black: [
        'bg-black',
        'text-white',
        ...properties.disabled ? [
            'opacity-50',
            'cursor-not-allowed',
        ] : [
            'hover:bg-gray-800',
            'focus:bg-gray-800',
            'active:bg-gray-700',
        ],
    ],
    white: [
        'bg-white',
        'border',
        ...properties.disabled ? [] : [
            'hover:bg-gray-100',
            'focus:bg-gray-100',
            'active:bg-gray-200',
        ],
    ],
    gray: [
        'bg-gray-50',
        'text-black',
        ...properties.disabled ? [
            'opacity-50',
            'cursor-not-allowed',
        ] : [
            'hover:bg-gray-100',
            'focus:bg-gray-100',
            'active:bg-gray-200',
        ],
    ],
    red: [
        'bg-red-500',
        'text-white',
        ...properties.disabled ? [
            'opacity-50',
            'cursor-not-allowed',
        ] : [
            'hover:bg-red-600',
            'focus:bg-red-600',
            'active:bg-red-700',
        ],
    ],
    green: [
        'bg-green-500',
        'text-white',
        ...properties.disabled ? [
            'opacity-50',
            'cursor-not-allowed',
        ] : [
            'hover:bg-green-600',
            'focus:bg-green-600',
            'active:bg-green-700',
        ],
    ],
    orange: [
        'bg-orange-500',
        'text-black',
        ...properties.disabled ? [
            'opacity-50',
        ] : [
            'hover:bg-orange-600',
            'focus:bg-orange-700',
            'active:bg-orange-700',
        ],
    ],
    danger: [
        'bg-transparent',
        'text-red-500',
        'border',
        'border-red-500',
        ...properties.disabled ? [
            'opacity-50',
        ] : [
            'hover:bg-red-50/30',
            'focus:bg-red-200/30',
            'active:bg-red-200/30',
        ],
    ],
    checkout: [
        'bg-[#118a0a]',
        'text-white',
        ...properties.disabled ? [
            'opacity-50',
            'cursor-not-allowed',
        ] : [
            'hover:bg-green-600',
            'focus:bg-green-600',
            'active:bg-green-700',
        ],
    ],
}));

const classes = computed(() => {
    let array: string[] = [];

    switch (properties.size) {
        case 'sm': {
            array = [
                ...array,
                'text-sm',
                'leading-4',
                'tracking-4xl',
                'p-xs',
            ];
            break;
        }
        case 'md': {
            array = [
                ...array,
                'uppercase',
                'text-sm',
                'leading-none',
                'tracking-5xl',
                'p-sm',
                'whitespace-nowrap',
            ];
            break;
        }
        case 'lg': {
            array = [
                ...array,
                'uppercase',
                'text-xl',
                'leading-none',
                'tracking-6xl',
                'p-md',
                'whitespace-nowrap',
            ];
            break;
        }
        case 'wide': {
            array = [
                ...array,
                'uppercase',
                'text-xl',
                'leading-none',
                'tracking-6xl',
                'p-md',
                'break-words whitespace-normal',
            ];
            break;
        }
        default: {
            array = [
                ...array,
                'uppercase',
                'text-base',
                'leading-none',
                'tracking-5xl',
                'p-sm',
            ];
        }
    }

    if (properties.theme) {
        array = [
            ...array,
            ...themes.value[properties.theme],
        ];
    }

    return array;
});
</script>
