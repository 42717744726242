<template>
    <v-ga-product v-slot="{ select }"
        :product="product">
        <div class="relative">
            <!-- Wishlist -->
            <button v-if="type === 'SimpleProduct'"
                type="button"
                class="group absolute right-0 top-0 z-20 outline-none"
                :title="tg('aria.add-to-wishlist')"
                @click.stop="addToWishlist">
                <v-icon
                    icon="heart"
                    class="m-xs size-[18px] group-hover:fill-red-500
                        group-focus:fill-red-500 group-active:fill-red-600
                        md:size-md" />
            </button>

            <nuxt-link :to="url"
                class="group"
                :title="tg('accessibility.open-product', { product: product.title })"
                @click="onClick($event, select)">
                <div class="relative flex aspect-square items-center justify-center rounded bg-white"
                    :class="{
                        'p-sm md:p-sm': imageType === 'cutout',
                        'overflow-hidden': imageType === 'model',
                    }">
                    <v-sale-tag v-if="promotion !== undefined"
                        class="z-10"
                        :promotion="promotion"
                        theme="black"
                        :price="price"
                        :original-price="originalPrice"
                        :club-price="clubPrice"
                        is-used-in-search-page />

                    <div class="absolute inset-0 rounded"
                        :class="{
                            'bg-gray-500/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100':
                                imageType === 'model',
                            'bg-gray-500/5 group-hover:bg-gray-500/10': imageType === 'cutout',
                        }"></div>

                    <!-- Primary image -->
                    <nuxt-picture v-if="image"
                        :key="image.url"
                        :src="image.url"
                        :alt="product.title || ''"
                        loading="lazy"
                        :width="image.width"
                        :height="image.height"
                        :img-attrs="{
                            class: `w-full h-full ${imageType === 'cutout'
                                ? 'object-contain py-2' : 'object-cover'}`,
                        }"
                        class="aspect-square"
                        :class="{
                            // eslint-disable-next-line max-len
                            'size-full transition-opacity duration-300 ease-in-out group-hover:opacity-0': hasHoverImage,
                        }"
                        :sizes="imageType === 'cutout'
                            ? 'xs:50vw sm:252px md:232px lg:285px xl:244px 2xl:308px'
                            : 'xs:50vw sm:296px md:376px lg:244px xl:308px 2xl:372px'
                        " />

                    <!-- Secondary hover image -->
                    <nuxt-picture v-if="hasHoverImage"
                        :key="hoverImage.url"
                        :src="hoverImage.url"
                        :alt="product.title || ''"
                        :width="hoverImage.width"
                        :height="hoverImage.height"
                        :img-attrs="{
                            class: `w-full h-full ${hoverImageType === 'cutout'
                                ? 'object-contain py-2' : 'object-cover'}`,
                        }"
                        class="absolute inset-0 p-md opacity-0 mix-blend-multiply transition-opacity
                            duration-300 ease-in-out group-hover:opacity-100"
                        :sizes="(hoverImageType === 'cutout' && isSearch
                            ? 'xs:50vw sm:252px md:285px lg:180px xl:244px 2xl:308px'
                            : 'xs:50vw sm:296px md:376px lg:244px xl:308px 2xl:372px')
                        " />

                    <!-- Brand logo -->
                    <div class="absolute bottom-xs left-0 flex flex-row
                        items-end gap-x-2 gap-y-1
                        overflow-auto px-xs scrollbar-none md:gap-y-2">
                        <!-- Brand logo / mobile -->
                        <nuxt-picture v-if="brandLogo"
                            class="block lg:hidden"
                            :alt="brandTitle || brand"
                            :src="brandLogo"
                            :width="32"
                            :height="32"
                            sizes="xs:50vw sm:32px md:32px lg:32px xl:32px 2xl:32px'">
                        </nuxt-picture>

                        <!-- Brand logo / desktop -->
                        <nuxt-picture v-if="brandLogo"
                            class="hidden lg:block"
                            :alt="brandTitle || brand"
                            :src="brandLogo"
                            :width="48"
                            :height="48"
                            sizes="xs:50vw sm:48px md:48px lg:48px xl:48px 2xl:48px'">
                        </nuxt-picture>
                    </div>

                    <!-- Tuotekortin tagit -->
                    <div class="absolute bottom-xs right-0 flex flex-row
                        items-end gap-x-2 gap-y-1
                        overflow-auto px-xs scrollbar-none md:gap-y-2">
                        <template v-for="badge in textProductBadges"
                            :key="badge.id">
                            <v-product-card-badge v-if="badge.listingTitle && badge.listingTitle.length > 0"
                                :badge="badge">
                                {{ badge.listingTitle }}
                            </v-product-card-badge>
                        </template>
                    </div>
                </div>

                <div class="p-2">
                    <div class="flex flex-row justify-between group-hover:underline">
                        <v-subtitle
                            class="truncate font-bold leading-4 tracking-xl md:text-base md:leading-5"
                            size="sm"
                        >
                            {{ title }}
                        </v-subtitle>
                    </div>

                    <p class="truncate font-sans text-sm text-[#565050] md:text-base">
                        {{ specification }}
                    </p>

                    <div v-if="originalPrice"
                        class="flex items-baseline space-x-2">
                        <template v-if="clubPrice">
                            <p class="font-prenton-cond text-lg leading-5 tracking-xl text-red-500
                                md:text-3xl md:leading-9">
                                <span v-if="type === 'ConfigurableProduct'"
                                    class="text-base">
                                    {{ t('product-part-payment-prefix') }}
                                </span>

                                {{ formatCurrency(clubPrice) }}
                            </p>

                            <p v-if="clubPrice !== originalPrice"
                                class="font-sans text-xs tracking-xl text-gray-500 line-through
                                    md:text-sm">
                                {{ formatCurrency(originalPrice) }}
                            </p>
                        </template>

                        <template v-else>
                            <p class="font-prenton-cond text-lg leading-5 tracking-xl text-red-500
                                md:text-3xl md:leading-9">
                                <span v-if="type === 'ConfigurableProduct'"
                                    class="text-base">
                                    {{ t('product-part-payment-prefix') }}
                                </span>

                                {{ formatCurrency(price) }}
                            </p>

                            <p v-if="price !== originalPrice"
                                class="font-sans text-xs tracking-xl text-gray-500 line-through
                                    md:text-sm">
                                {{ formatCurrency(originalPrice) }}
                            </p>
                        </template>

                        <!-- Desktop ratings-->
                        <div v-if="rating"
                            class="hidden flex-1 items-center justify-end space-x-px
                            self-center pt-2 text-xs md:text-sm lg:flex">
                            <template v-for="i in Math.ceil(rating)"
                                :key="i">
                                <v-icon v-if="(i - 0.5) <= rating"
                                    class="fill-[#f6ca6c] stroke-brand-black"
                                    :icon="getIcon(i, rating)" />
                            </template>
                        </div>
                    </div>

                    <!-- Mobile ratings -->
                    <div v-if="rating"
                        class="flex flex-1 items-center justify-start space-x-px
                        self-center pt-2 text-xs md:text-sm lg:hidden">
                        <template v-for="i in Math.ceil(rating)"
                            :key="i">
                            <v-icon v-if="(i - 0.5) <= rating"
                                class="fill-[#f6ca6c] stroke-brand-black"
                                :icon="getIcon(i, rating)" />
                        </template>
                    </div>

                    <div v-if="deliveryEstimateDays > 0">
                        <p class="mt-2 font-sans text-sm text-[#146716] md:text-base">
                            {{ formatDeliveryEstimateText(deliveryEstimateDays) }}
                        </p>
                    </div>
                </div>
            </nuxt-link>
        </div>
    </v-ga-product>
</template>

<script lang="ts" setup>
import type { Product } from '@apptus/esales-api';
import { capitalCase } from 'case-anything';
import { useGtm } from '@gtm-support/vue-gtm';
import { useToast } from '~~/store/toast';
import type { PromotionData, ElevateProductBadge } from '~~/types';

const properties = defineProps<{
    product: Product
    isSearch?: boolean
}>();

const wishlist = useCart(true);
const toast = useToast();
const { t } = useI18n();
const { t: tg } = useI18n({ useScope: 'global' });
const { $eventbus } = useNuxtApp();
const { notifyClick } = useElevate();
const gtm = useGtm();
const { formatDays: formatDeliveryEstimateText } = useDeliveryEstimateText();

const { getStringValues } = useElevateProduct(properties.product);
const { format: formatCurrency } = useCurrency();
const { getIcon } = useReviews();

const image = computed(() => properties.product.imageInfo.images[0]?.sources[0]);
const imageType = computed(() => (
    properties.product.imageInfo.images[0]?.custom?.type === 'model' ? 'model' : 'cutout'
));

const hoverImage = computed(() => properties.product.imageInfo.images[1]?.sources[0] ?? undefined);
const hoverImageType = computed(() => (
    properties.product.imageInfo.images[1]?.custom?.type === 'model' ? 'model' : 'cutout'
));

const hasHoverImage = computed(() => hoverImage.value !== undefined && imageType.value === 'model');

const title = computed(() => (
    capitalCase(getStringValues('title')[0] || properties.product.title.split(' ')[0])
));
const specification = computed(() => (
    (getStringValues('specification')[0] || properties.product.title.split(' ').slice(1).join(' ')).toLocaleLowerCase()
));
const price = computed(() => properties.product.sellingPrice.min);
const originalPrice = computed(() => properties.product.listPrice.min);
const url = computed(() => properties.product.link);

const type = computed(() => getStringValues('type')[0]);
const productId = computed(() => getStringValues('id')[0]);
const sku = computed(() => getStringValues('sku')[0]);
const rating = computed(() => properties.product.rating);

const productBadges = computed<ElevateProductBadge[]>(() => {
    const value = getStringValues('productBadges')[0];

    return value ? JSON.parse(value) : [];
});
const textProductBadges = computed<ElevateProductBadge[]>(() => productBadges.value.filter((badge) => !badge.icon));

const promotion = computed<PromotionData | undefined>(() => {
    const value = getStringValues('promotion')[0];

    return value ? JSON.parse(value) : undefined;
});

const deliveryEstimateDays = computed(() => (properties.product.custom?.delivery_estimate_days
    ? Number.parseInt((properties.product.custom?.delivery_estimate_days[0].id), 10)
    : 0));

const clubPrice = computed<number | undefined>(() => {
    if (properties.product.custom?.clubPrice) {
        return Number(properties.product.custom?.clubPrice[0].id);
    }

    return properties.product.variants
        .map((variant) => variant.custom?.clubPrice?.[0]?.id)
        .filter((value): value is string => !!value)
        .map(Number)
        .sort((a, b) => a - b)
        .at(0);
});

const brand = computed(() => properties.product.brand);
const brandTitle = computed(() => getStringValues('brand_title')[0]);
const brandLogo = computed(() => getStringValues('brand_logo')[0]);

async function onClick(event: Event, callback: (event: Event) => void) {
    await notifyClick(properties.product.ticket);

    callback(event);
}

const gaProduct = computed(() => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    item_id: sku.value,
    item_name: properties.product.title,
    item_unique_id: productId.value,
    ...properties.product.brand ? { item_brand: properties.product.brand } : {},
    price: properties.product.sellingPrice.min,
    quantity: 1,
    /* eslint-enable @typescript-eslint/naming-convention */
}));

async function addToWishlist() {
    if (!productId.value) {
        return;
    }

    gtm?.trackEvent({
        /* eslint-disable @typescript-eslint/naming-convention */
        event: 'add_to_wishlist',
        _clear: true,
        ecommerce: {
            currency: 'EUR',
            value: gaProduct.value.price,
            items: [gaProduct.value],
        },
        /* eslint-enable @typescript-eslint/naming-convention */
    });

    try {
        await wishlist.addProduct(productId.value);

        $eventbus.emit('product-added-to-wishlist', {
            productId: productId.value,
        });
    } catch {
        toast.add('warning', `${tg('toast.wishlist-warning')}`);
    }
}
</script>

<i18n lang="json">
{
    "et": {
        "quick-delivery": "Kiire tarne",
        "product-part-payment": "Maksa osade kaupa ",
        "product-part-payment-prefix": "al.",
        "product-part-payment-suffix": "/kuu"
    },
    "fi": {
        "quick-delivery": "Nopea toimitus",
        "product-part-payment": "Tai maksa erissä ",
        "product-part-payment-prefix": "alk.",
        "product-part-payment-suffix": "/kk"
    }
}
</i18n>
