import cloneDeep from 'clone-deep';
import type { ISbRichtext, SbRichTextOptions } from '@storyblok/js';
import { useModifyColor } from '~/composables/use-modify-color';

export default function renderCustomRichText(document: ISbRichtext | string) {
    const mySchema = cloneDeep(RichTextSchema);
    const { format: formatSbUrl } = useSbUrl();

    const resolver: SbRichTextOptions['resolver'] = (component, blok) => {
        switch (component) {
            case 'sb-content-image': {
                return `<img src="${blok.image.filename}" loading="lazy" class="sb-content-image">`;
            }
            case 'sb-content-button': {
                const buttonSize = (blok.size as 'sm' | 'md' | 'lg') || 'md'; // Default to 'md' if size is not provided
                const fontColor = blok.font_color?.color ?? '#ffffff';
                const backgroundColor = blok.background_color?.color ?? '#000000';
                const darkerBackgroundColor = useModifyColor(backgroundColor, 50);

                const sizeClasses = {
                    sm: 'text-sm leading-4 tracking-4xl p-xs',
                    md: 'uppercase text-md leading-none tracking-5xl p-sm whitespace-nowrap',
                    lg: 'uppercase text-lg leading-none tracking-6xl p-md whitespace-nowrap',
                };

                const linkClasses = `
                    rounded font-prenton-cond outline-none transition-colors my-2 duration-150 leading-4 tracking-4xl
                    bg-[var(--bg-color)] text-[var(--font-color)] hover:bg-[var(--hover-bg-color)]
                    focus:bg-[var(--hover-bg-color)] active:bg-[var(--hover-bg-color)]
                    no-underline ${sizeClasses[buttonSize]}`;

                const linkStyles = `
                    --font-color: ${fontColor};
                    --bg-color: ${backgroundColor};
                    --hover-bg-color: ${darkerBackgroundColor};`;

                return blok.link.anchor
                    ? `<a href="${formatSbUrl(blok.link.cached_url)}#${blok.link.anchor}"
                        class="${linkClasses}" style="${linkStyles};">
                        ${blok.title}</a>`
                    : `<a href="${formatSbUrl(blok.link.cached_url)}"
                        class="${linkClasses}" style="${linkStyles};"
                    >${blok.title}</a>`;
            }
            case 'sb-freshworks-button': {
                return `<button type="button"
                    class="rounded font-prenton-cond outline-none transition-colors duration-150 text-sm
                    leading-4 tracking-4xl p-xs bg-black text-white hover:bg-gray-800 focus:bg-gray-800
                    active:bg-gray-700 md:text-base md:leading-5 no-underline"
                onclick="FreshworksWidget('open');">
                ${blok.button_text}
            </button>`;
            }
            default: {
                return 'Resolver not defined';
            }
        }
    };

    return (
        typeof document === 'object'
            ? renderRichText(document, {
                schema: mySchema,
                resolver,
            })
            : document
    );
}
