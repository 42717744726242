import { init, replayIntegration, browserTracingIntegration } from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter();

    const {
        public: {
            sentry: {
                dsn,
                errorSampleRate,
                tracesSampleRate,
                replaysSessionSampleRate,
                replaysOnErrorSampleRate,
                environment,
                release,
            },
        },
    } = useRuntimeConfig();

    if (dsn) {
        init({
            app: nuxtApp.vueApp,
            dsn,
            release,
            environment,
            integrations: [
                browserTracingIntegration({
                    router,
                }),
                replayIntegration({
                    // This keeps sentry from trying to snoop the Avarda checkout form's contents for its replay recording - if you don't disable sentry here, it will trigger a weird infinite loop that effectively kills the site! The issue has something to do with the avarda checkout widget using closed shadow DOM, which sentry can't properly deal with.
                    block: ['#checkout-form'],
                }),
            ],
            sampleRate: errorSampleRate,
            tracesSampleRate,
            replaysSessionSampleRate,
            replaysOnErrorSampleRate,
        });
    }
});
