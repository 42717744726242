import { trim } from 'es-toolkit';

/** This function formats the URL's coming from Storyblok.
 * To ensure all the URLs are formatted correctly, this function adds 'https://' to the URL if it's missing.
 */

export function useSbUrl() {
    return {
        format: (url?: string): string => {
            if (!url || url.length === 0) {
                return '#';
            }

            if (/^(https?:\/\/|www\.|#)/.test(url)) {
                // Add https:// to the URL if it's missing
                if (/^www\./.test(url)) {
                    return `https://${url}`;
                }
                return url;
            }

            return `/${trim(url, '/')}`;
        },
    };
}
