export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"application-name","content":"Masku"},{"name":"msapplication-TileColor","content":"#EF1C35"},{"name":"msapplication-TileImage","content":"/mstile-144x144.png"},{"name":"msapplication-square70x70logo","content":"/mstile-70x70.png"},{"name":"msapplication-square150x150logo","content":"/mstile-150x150.png"},{"name":"msapplication-wide310x150logo","content":"/mstile-310x150.png"},{"name":"msapplication-square310x310logo","content":"/mstile-310x310.png"}],"link":[{"rel":"apple-touch-icon-precomposed","sizes":"57x57","href":"/apple-touch-icon-57x57.png"},{"rel":"apple-touch-icon-precomposed","sizes":"60x60","href":"/apple-touch-icon-60x60.png"},{"rel":"apple-touch-icon-precomposed","sizes":"72x72","href":"/apple-touch-icon-72x72.png"},{"rel":"apple-touch-icon-precomposed","sizes":"114x114","href":"/apple-touch-icon-114x114.png"},{"rel":"apple-touch-icon-precomposed","sizes":"120x120","href":"/apple-touch-icon-120x120.png"},{"rel":"apple-touch-icon-precomposed","sizes":"144x144","href":"/apple-touch-icon-144x144.png"},{"rel":"apple-touch-icon-precomposed","sizes":"152x152","href":"/apple-touch-icon-152x152.png"},{"rel":"icon","type":"image/png","href":"/favicon-16x16.png","sizes":"16x16"},{"rel":"icon","type":"image/png","href":"/favicon-32x32.png","sizes":"32x32"},{"rel":"icon","type":"image/png","href":"/favicon-96x96.png","sizes":"96x96"},{"rel":"icon","type":"image/png","href":"/favicon-128.png","sizes":"128x128"},{"rel":"icon","type":"image/png","href":"/favicon-196x196.png","sizes":"196x196"},{"rel":"icon","type":"image/svg+xml","href":"/favicon.svg"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"et"},"viewport":"width=device-width, initial-scale=1","charset":"utf-8"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"